.dashboard {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .dashboard h1 {
    text-align: center;
    color: #333;
  }
  
  .dashboard .form-group {
    margin-bottom: 15px;
  }
  
  .dashboard label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .dashboard select,
  .dashboard input[type="time"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .dashboard button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dashboard button:hover {
    background-color: #0056b3;
  }
  
  .dashboard ul {
    list-style: none;
    padding: 0;
  }
  
  .dashboard li {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dashboard li button {
    padding: 5px 10px;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
  
  .dashboard li button:hover {
    background-color: #c82333;
  }
  